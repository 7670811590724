<template>
  <artmisads-modal
    :open="true"
    title="Payment Details"
    :width="1200"
    :onCancel="() => props.setDetailData({})"
    :destroyOnClose="true"
    :footer="null"
  >
    <a-spin :spinning="spinning">
      <div class="payment">
        <div class="info">
          <div class="head">
            <p>Payment Info</p>
            <div class="head-btn">
              <artmisads-select-button @click="toInvoicePdf">
                <svg class="icon icon-daochu1" aria-hidden="true">
                  <use xlink:href="#icon-daochu1"></use>
                </svg>
                Download Invoice
              </artmisads-select-button>
              <artmisads-button-special @click="financeSellerBillingsDetailExport">
                <svg class="icon daochu" aria-hidden="true">
                  <use xlink:href="#icon-daochu"></use>
                </svg>
                <span class="btn-text">Export</span>
              </artmisads-button-special>
            </div>
          </div>
          <div class="tag">
            <div class="item date">
              <span class="line"></span>
              <div class="text">
                <span class="name">Billing Date:</span>
                <span class="num">{{moment(props.detailData.billingDateTimestamp).tz(AMAZON_COUNTRY_TIME_TZ[props.detailData.marketplace]).format('YYYY-MM')}}</span>
              </div>
            </div>
            <div class="item payment-id">
              <span class="line"></span>
              <div class="text">
                <span class="name">Payment ID:</span>
                <span class="num">{{props.detailData.billingId}}</span>
              </div>
            </div>
            <div class="item amount">
              <span class="line"></span>
              <div class="text">
                <span class="name">Payment Amount:</span>
                <span class="num"> {{AMAZON_CURRENCY_MAPPER[props.detailData.marketplace]}}{{props.detailData.amount}}</span>
              </div>
            </div>
            <div class="item marketplace">
              <span class="line"></span>
              <div class="text">
                <span class="name">Marketplace:</span>
                <div class="marketplace-tag">
                  <div>
                      <Icon :name="COUNTRY_BASE_MAP[props.detailData.marketplace].icon"></Icon>
                      <span>{{props.detailData.marketplace}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="item status">
              <span class="line"></span>
              <div class="text">
                <span class="name">Status:</span>
                <financeStatus :type="props.detailData.status"/>
              </div>
            </div>
            <div class="item due-date">
              <span class="line"></span>
              <div class="text">
                <span class="name">Due Date:</span>
                <span class="num">{{props.detailData.dueDate}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="detail">
          <p>Details</p>
          <div class="filter">
            <div class="button-groups">
              <buttonGroups
                :pagination="{
                  total: listTotal,
                  current: params.page,
                  size: params.size,
                }"
                :tableData="list"
                @pageChange="onPageChange"
              />
            </div>
            <div class="search">
              <a-input-search
                v-model:value="params.keyword"
                placeholder="search..."
                @search="getFinanceSellerBillingsDetail"
                allowClear="true"
              />
            </div>
          </div>
          <div class="table">
            <artmisads-table
              :columns="columns"
              row-key="id"
              :data-source="list"
              :pagination="{
                total: listTotal,
                current: params.page,
                pageSize: params.size,
                showSizeChanger: true
              }"
              :change="onChangePage"
              tableLayout="fixed"
            >
              <template #bodyCell="{ column, record, text }">
                <template v-if="column.name === 'showCurrency'">
                  {{AMAZON_CURRENCY_MAPPER[record.marketplace]}}{{text}}
                </template>
              </template>
            </artmisads-table>
          </div>
        </div>
      </div>
    </a-spin>
  </artmisads-modal>
</template>

<script setup>
import { useRouter } from "vue-router";
import moment from 'moment';
import { keyBy } from 'lodash';
import Icon from '~/components/Icon.vue';
import financeStatus from '~/components/financeStatus';
import { message, Modal } from "ant-design-vue";
import { AMAZON_CURRENCY_MAPPER, AMAZON_COUNTRY_TIME_TZ } from '~/contants/common';
import { ref, onBeforeMount, computed, onMounted, watch } from "vue";
import ButtonGroups from '../../../Brands/components/buttonGroups.vue';
import { financeApi } from "~/server";
import { COUNTRY_BASE } from "~/contants/common";

const COUNTRY_BASE_MAP = keyBy(COUNTRY_BASE, 'host');
const router = useRouter();
const props = defineProps(["detailData", 'setDetailData', 'hasCompleteInfo', 'setShowNotice']);
const list = ref([]);
const listTotal = ref(0);
const spinning = ref(false);

const params = ref({
  page: 1,
  size: 10,
  keyword: null,
});

const columns = [
    {
      title: 'Amazon Seller',
      dataIndex: 'sellerName',
      width: 120,
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      width: 120,
    },
    {
      title: 'Publisher Name',
      dataIndex: 'publisherName',
      width: 120,
    },
    {
      title: 'Sales for Commissions',
      dataIndex: 'saleCommissionAmount',
      width: 150,
      name: 'showCurrency',
    },
    {
      title: 'Commissions',
      dataIndex: 'commissionAmount',
      width: 140,
      name: 'showCurrency',
    },
  ]

  const toInvoicePdf = () => {
    if (props.hasCompleteInfo) {
      const { href } = router.resolve({ path: `/seller/invoice-pdf/${props.detailData.billingId}` });
      window.open(href, '_blank');
    } else {
      props.setShowNotice(true);
    }
  }

const onChangePage = (data, filters) => {
  const { current, pageSize } = data;
  params.value.page = current;
  params.value.size = pageSize;
  getFinanceSellerBillingsDetail();
}

const getFinanceSellerBillingsDetail = async () => {
  spinning.value = true;
  const paramsData = { ...params.value, billingId: props.detailData.billingId, keyword: params.value.keyword };
  const res = await financeApi.getFinanceSellerBillingsDetail(paramsData);
  const { success, data } = res;
  if (success) {
    listTotal.value = data.total;
    list.value = data.data;
  }
  spinning.value = false;
};

const onPageChange = current => {
    params.value.page =  current;
    getFinanceSellerBillingsDetail();
  }

const financeSellerBillingsDetailExport = () => {
  const finalParams = {
    qs: {
      billingId: props.detailData.billingId, keyword: params.value.keyword || ''
    },
    fileName: `payment-detail-${moment().format('YYYY-MM-DD')}.csv`,
  }
  financeApi.financeSellerBillingsDetailExport(finalParams);
}

onBeforeMount(() => {
  getFinanceSellerBillingsDetail();
});
</script>

<style lang="less" scoped>
.payment {
  .info {
    margin-top: 6px;
    .head {
      display: flex;
      justify-content: space-between;
      > p {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 28px;
      }
      .head-btn {
        display: flex;
        button:first-child {
          display: flex;
          svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            align-self: center;
          }
        }
        button:last-child {
          cursor: pointer;
          display: flex;
          margin-left: 16px;
          .jiantou {
            width: 16px;
            height: 16px;
            fill: var(--dark-3);
            margin-left: 8px;
          }
          .daochu {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            fill: var(--dark-1);
          }
          > svg {
            align-self: center;
          }
          > span {
            align-self: center;
          }
          .btn-text {
            align-self: center;
            font-weight: 500;
            line-height: 20px;
            margin-right: 8px;
          }
        }
      }
      
    }
  }
  .tag {
    display: flex;
    padding: 11px 0 11px 16px;
    background-color: var(--bg-gray-1);
    border-radius: 8px;
    margin-top: 16px;
    .item {
      display: flex;
      width: 200px;
      .line {
        width: 3px;
        height: 40px;
        opacity: 0.85;
        border-radius: 2px;
      }
      .text {
        display: flex;
        flex-direction: column;
        margin-left: 11px;
        .name {
          font-weight: 400;
          font-size: 13px;
          color: var(--dark-2);
          letter-spacing: 0.3px;
          line-height: 18px;
          margin-bottom: 4px;
        }
        .num {
          color: var(--dark-1);
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 20px;
        }
      }
    }
    .due-date {
      .line {
        background-color: var(--tag-bg-5);
      }
    }
    .date {
      .line {
        background-color: var(--tag-bg-1);
      }
    }
    .amount {
      .line {
        background-color: var(--tag-bg-3);
      }
    }
    .marketplace {
      .marketplace-tag {
        display: inline-block;
        > div {
          display: flex;
          background-color: var(--bg-gray-8);
          border-radius: 4px;
          padding: 0 6px;
          line-height: 20px;
          > svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            align-self: center;
          }
        }
      }
      .line {
        background-color: var(--tag-bg-7);
      }
    }
    .payment-id {
      width: 280px;
      .line {
        background-color: var(--tag-bg-2);
      }
    }
    .status {
      width: 160px;
      .line {
        background-color: var(--tag-bg-9);
      }
    }
  }
  .detail {
    > p {
      font-weight: 600;
      font-size: 20px;
      color: var(--dark-1);
      letter-spacing: 0.5px;
      line-height: 28px;
      margin-top: 32px;
      margin-bottom: 16px;
    }
    .filter {
      display: flex;
      justify-content: space-between;
    }
    .table {
      margin-top: 16px;
    }
  }
  
}
</style>
